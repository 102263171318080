@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .pause {
        animation-play-state: paused;
    }
}

.datepicker input {
    @apply block w-full sm:text-sm border-gray-300 rounded-md !border-0 !ring-0 !py-2 !px-3
}

.datepicker fieldset {
    @apply !border !border-gray-300 shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm
}

.datepicker .MuiFormControl-root {
    @apply rounded-md !py-2 !px-3
}

@keyframes homepage-hero-image-tracks {
    to {
        transform: translate3d(0,-72px,0);
        opacity: 1
    }
}

.animate-homepage-image-tracks {
    animation: homepage-hero-image-tracks 1s ease-out forwards
}


:root {
    --w3m-background-color: #0ea5e9 !important;
    --w3m-accent-color: #0ea5e9 !important;
}